#vanta {
    background-color: #000010;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100svh;
    overflow: hidden;
    z-index: -1;
}

.cover {
    display: flex;
    width: 100%;
    height: 100svh;
}

.coverTitleContainer {
    margin: auto;
    border-radius: 40%;
    padding: 1rem;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    font-family: 'Calibre','Inter','San Francisco','SF Pro Text',system-ui,sans-serif;
}

.cover-text1 {
    color: white;
    font-family: inherit;
    font-size: clamp(18px, 2vw, 25px);
    font-weight: 600;
}

.cover-text2 {
    color: var(--cyan-2);
    font-family: inherit;
    font-size: clamp(40px, 8vw, 90px);
    font-weight: 600;
}

.cover-text3 {
    color: white;
    font-family: inherit;
    font-size: clamp(22px, 4vw, 35px);
    font-weight: 600;
}

.coverButtonContainer {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 8%;
}

.coverScrollButton {
    cursor: pointer;
    margin: auto;
}

.arrow-icon {
    height: clamp(40px, 8vw, 50px);
    fill:  var(--cyan-2);
}
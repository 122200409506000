.nonparallax {
    display: none;
    height: 100svh;
    background-image: url("../../../public/parallax/nonparallax.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax {
    display: block;
    position: relative;
    height: 1500px;
    height: 200vh;
    overflow: hidden;
}

.parallax-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: auto 100vh;
    background-position: top center;
    background-repeat: no-repeat;
}

#background {
    background-image: url(../../../public/parallax/background.png);
    background-size: auto 100%;
    /* background-attachment: fixed; */
}

#balloon1 {
    background-image: url(../../../public/parallax/balloon1.png);
}

#balloon2 {
    background-image: url(../../../public/parallax/balloon2.png);
}

#balloon1-light {
    background-image: url(../../../public/parallax/balloon1-light.png);
    mix-blend-mode: screen;
}

#balloon2-light {
    background-image: url(../../../public/parallax/balloon2-light.png);
    mix-blend-mode: screen;
}

#cloud1 {
    background-image: url(../../../public/parallax/cloud1.png);
}

#cloud2 {
    background-image: url(../../../public/parallax/clouds.png);
}

/* #cloud3 {
    background-image: url(../../../public/parallax/cloud3.png);
} */

#moon {
    background-image: url(../../../public/parallax/moon.png);
    mix-blend-mode: screen;
}

#moon-light {
    background-image: url(../../../public/parallax/moon-light.png);
    mix-blend-mode: screen;
}

#mountain1 {
    background-image: url(../../../public/parallax/mountain1.png);
    background-position-y: bottom;
    transform: translateY(-20px);
}

#mountain2 {
    background-image: url(../../../public/parallax/mountain2.png);
    background-position-y: bottom;
    transform: translateY(-85px);
}

#surface {
    background-image: url(../../../public/parallax/surface.png);
    background-position-y: bottom;
}

#balloon1, #balloon1-light {
    animation: floatAnimation 5s infinite alternate ease-in-out;;
}
#balloon2, #balloon2-light {
    animation: floatAnimation2 5s infinite alternate ease-in-out;;
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(30px);
    }
  }
@keyframes floatAnimation2 {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-15px);
    }
  }


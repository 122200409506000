.footer {
  background-color: black;
  display: flex;
  flex-direction: column;  
  color: var(--azure);
  font-size: 10px;
  padding: 1rem 0;
}

.footer span {
  margin: auto;
}

.footer img {
  height: 50px;
  object-fit: contain;
}

.left-side, .right-side {
    position: fixed;
    width: 40px;
    left: calc(5vw - 30px);
    bottom: 0;
    color: var(--cyan);
    z-index: 10;
}

.right-side {
    left: auto;
    right: calc(5vw - 30px);
}

.left-side ul, .right-side ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    gap: 1rem;
}

.left-side ul::after, .right-side ul::after {
    content: "";
    display: block;
    width: 1px;
    height: 15svh;
    margin: 1rem auto 0 auto;
    background-color: var(--cyan);
}

.left-side a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 23px;
    transition: scale .2s ease;
}

.left-side a:hover {
    scale: 1.3;
}

.right-side ul {
    gap: 1.2rem;
}

.right-side a {
    position: relative;
}

.dot {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid var(--cyan);
    transition: background-color 1s ease, scale .3s;
}

.dot-label {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    opacity: 0;
    transition: opacity .3s ease-in;
    pointer-events: none;
}

.right-side a:hover .dot {
    scale: 1.1;
}

.right-side a:hover .dot-label {
    opacity: 1;
}

.active-dot {
    background-color: var(--cyan);
}

/* 




------------------------------ Responsive Settings ------------------------------




 */

 @media only screen and (max-width: 600px) {  
    .left-side, .right-side {
        display: none;
    }
  }

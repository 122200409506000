@import "./variables.css";

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--dark-gunmetal-2);
  font-family: "Source Sans Pro",sans-serif;
  line-height: 1.3;
  overflow-x: hidden;
}

.noScroll {
  overflow: hidden;
}

h1 {
  font-size: clamp(26px, 5vw, 32px);
  text-align: center;
  color: white;
  white-space: nowrap;
}

h2 {
  font-size: clamp(20px, 3vw, 24px);
  text-align: center;
  color: var(--azure);
}

h3 {
  font-size: clamp(18px, 2vw, 20px);
  text-align: center;
  color: var(--azure);
}

h4 {
  color: var(--cyan);
}

p {
  font-size: clamp(16px, 1vw, 18px);
  color: var(--pastel-blue);
}

a {
  text-decoration: none;
  color: inherit;
}

.text-gap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.main {
  background-color: var(--dark-gunmetal-2);
  min-height: 100svh;
}

section {
  max-width: 80vw;
  margin: 0 auto;
  padding: 6rem 0;
}

.section-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 2rem;
}

.section-heading::before, .section-heading::after {
  background-color: var(--cyan);
  content: "";
  height: 1px;
  width: 50%;
}

.section-content {
  display: flex;
  margin: 0 auto;
  padding: 3rem 0;
}

.button {
  background-color: var(--cyan);
  display: flex;
  width: 202px;
  height: 52px;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  border: 1.5px solid var(--cyan);
  border-radius: 4px;
  z-index: -1;
}

.button::after {
  background-color: var(--dark-gunmetal);
  background-color: black;
  position: absolute;
  content: "View My CV";
  display: flex;
  color: var(--cyan);
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  border: 1.5px solid var(--cyan);
  border-radius: 4px;
  transition: transform 0.15s linear;
}

.button:hover::after {
  transform: translate(-5px, -5px);
}

/* 




------------------------------ Scrollbar ------------------------------




 */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: black;
}
::-webkit-scrollbar-thumb {
  background-color: var(--azure);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--silver-blue);
}

.item-wrapper {
    position: relative;
    display: inline-flex;
    width: clamp(65px, 10vw, 100px);
    height: clamp(65px, 10vw, 100px);
    margin: 8px;
    border-radius: 50%;
}

.item {
    background-color: var(--dark-slate-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: transform 1s;
    transform-style: preserve-3d;
}

.name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid var(--cyan);
    color: var(--cyan);
    /* font-size: 12px; */
    font-size: clamp(12px, 1vw, 16px);
    backface-visibility: hidden;
    transform: rotateY(180deg);
}

.small-item {
    width: clamp(60px, 7vw, 70px);
    height: clamp(60px, 7vw, 70px);
    margin: 4px;
}

.flipped {
    transform: rotateY(180deg);
}

/* 




------------------------------ About Section ------------------------------




*/

#about-section .section-content {
    max-width: 1000px;
    gap: 3rem;
}

#about-section .content-container-1 {
    flex: 5;
    /* order: 2; mobile*/
}

#about-section .content-container-2 {
    flex: 2;
    /* order: 1; mobile*/
}

.education-container {
    margin-top: 2rem;
    text-align: center;
}

.education {
    background-color: var(--dark-slate-blue);
    border-radius: 8px;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    padding: 1rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.edu-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
}

.edu-logo {
    display: block;
    height: 4rem;
    margin: 0 auto 1rem auto;
}

.pfp-wrapper {
    position: relative;
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 5rem;
}

.pfp {
    width: 250px;
    height: 250px;
    background-image: url("../../../public/images/pfp.jpg");
    background-size: cover;
    border: 3px solid var(--cyan);
    border-radius: 8px;
    z-index: 1;
    transition: transform 0.15s linear;
}

.pfp-screen {
    background-color: rgba(0, 255, 255, .2);
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.15s linear;
}

.pfp-shadow {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid var(--cyan);
    border-radius: 8px;
    right: -20px;
    bottom: -20px;
    transition: transform 0.15s linear;
}

.pfp-wrapper:hover .pfp-screen {
    opacity: 0;
}

.pfp-wrapper:hover .pfp {
    transform: translate(-5px, -5px);
}

.pfp-wrapper:hover .pfp-shadow {
    transform: translate(5px, 5px);
}

.cv-button {
    background-color: var(--cyan);
    display: flex;
    width: 202px;
    height: 52px;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 1.5px solid var(--cyan);
    border-radius: 4px;
    z-index: -1;
}

.cv-button::after {
    background-color: var(--dark-gunmetal-2);
    position: absolute;
    content: "View My CV";
    display: flex;
    color: var(--cyan);
    text-decoration: none;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border: 1.5px solid var(--cyan);
    border-radius: 4px;
    transition: transform 0.15s linear;
}

.cv-button:hover::after {
    transform: translate(-5px, -5px);
}

/* 




------------------------------ Skills Section ------------------------------




 */

#skills-section .section-content {
    max-width: 900px;
    gap: 2rem;
}

#skills-section .content-container-1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#skills-section .content-container-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 3rem;
}

#skills-section h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

#skills-section .icon {
    scale: 1.5;
    color: aqua;
}

.items-container {
    margin-top: 1rem;
    text-align: center;
}

.item-icon {
    position: absolute;
    fill: var(--azure);
    width: 50%;
    height: 50%;
    backface-visibility: hidden;
}

.know-item {
    background-color: var(--dark-slate-blue);
    padding: 10px;
    border-radius: 10px;
    display: inline-flex;
    margin: 5px;
    color: var(--azure);
    font-size: clamp(14px, 2vw, 16px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

/* 




------------------------------ WhatIDo Section ------------------------------




 */

#whatido-section .section-content {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    gap: 5vw;
}

#whatido-section [class^="content-container-"] {
    flex: 1;
    gap: 5vw;
    display: flex;
    flex-direction: row;
}

#whatido-section .content {
    background-color: var(--dark-slate-blue);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: scale 1s ease;
}

#whatido-section .content p {
    transition: color 1s ease;
}

#whatido-section .icon {
    align-self: center;
    width: 5rem;
    height: auto;
    margin: 2rem 0;
}

#whatido-section .content:hover {
    scale: 1.05;
}

#whatido-section .content:hover p {
    color: var(--azure);
}

/* 




------------------------------ Responsive Settings ------------------------------




 */

 @media only screen and (max-width: 750px) {  
    #about-section .section-content {
        flex-direction: column;
    }
    #about-section .content-container-1 {
        /* order: 2; */
    }
    #about-section .content-container-2 {
        /* order: 1; */
    }
  }

  @media only screen and (max-width: 650px) { 
    #skills-section .section-content {
        flex-direction: column;
    }

    #whatido-section [class^="content-container-"] {
        flex-direction: column;
    }
  }

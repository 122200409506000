.project-container {
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
}

.project-content {
    position: relative;
    grid-area: 1 / 7 / -1 / -1;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.3rem;
    z-index: 1;
    pointer-events: none;
}

.project-img {
    display: flex;
    position: relative;
    grid-area: 1 / 1 / -1 / 8;
}

.project-img img {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 0 40px var(--cyan-tran);
}

.project-img-screen {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: var(--denim);
    mix-blend-mode: multiply;
    opacity: 1;
    transition: opacity 1s ease;
}

.project-img-screen:hover {
    opacity: 0;
}

.project-overline {
    color: var(--cyan);
    pointer-events: auto;
}

.project-content h2 {
    text-align: inherit;
    pointer-events: auto;
}

.project-desc {
    background-color: var(--dark-slate-blue);
    padding: 1.5rem;
    border-radius: 5px;
    color: var(--pastel-blue);
    font-size: clamp(14px, 2vw, 16px);
    pointer-events: auto;
    transition: scale .5s ease, color .5s ease;
}

.project-desc:hover {
    scale: 1.05;
    color: var(--cyan-2);
}

.project-tech-list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    list-style: none;
    column-gap: 1.5rem;
    row-gap: .5rem;
}

.project-tech-list li {
    color: var(--pastel-blue);
    white-space: nowrap;
    pointer-events: auto;
    font-size: clamp(12px, 2vw, 16px);
}

.project-links {
    display: flex;
    justify-content: flex-end;
    gap: 1.8rem;
}

.link-icon {
    fill: var(--azure);
    width: 30px;
    height: 30px;
    cursor: pointer;
    pointer-events: auto;
    transition: scale .2s ease;
}

.link-icon:hover {
    fill: var(--cyan);
    scale: 1.2;
}

.reverse-project-content {
    grid-area: 1 / 1 / -1 / 7;
    text-align: left;
}

.reverse-project-img {
    grid-area: 1 / 6 / -1 / -1;
}

.reverse-project-list {
    justify-content: flex-start;
}

/* 




------------------------------ Responsive Settings ------------------------------




 */

 @media only screen and (max-width: 750px) {  
    .project-content {
        text-align: left;
        grid-column: 1 / -1;
        height: 100%;
        padding: 2rem;
    }
    .project-desc {
       background-color: transparent;
       padding: 0;
    }

    .project-img {
        grid-column: 1 / -1;
        height: 100%;
    }
    .project-img img {
        object-fit: cover;
    }
    .project-img-screen {
        opacity: 1 !important;
        background-color: var(--dark-slate-blue-2);
    }

    .project-tech-list {
        justify-content: flex-start;
    }
    .project-links {
        justify-content: flex-start;
    }
 }
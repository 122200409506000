#contact {
    width: 100%;
    background-color: var(--black-tran);
}

#contact .section-content {
    max-width: 900px;
    gap: 5rem;
}

#contact .content-container-1 {
    flex: 1;
}

#contact .content-container-2 {
    position: relative;
    flex: 1;
}

.contact-list {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    gap: 2rem;
}

.contact-list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--azure);
    font-size: clamp(18px, 2vw, 20px);
}

.contact-list a:hover {
    color: var(--cyan);
    text-decoration: underline;
}

.contact-icon {
    scale: 1.2;
    margin-right: 1.5rem;
    color: var(--cyan);
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
}

.inputError {
    margin-top: -15px;
    margin-left: 20px;
    color: red;
}

.inputBox {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--dark-gunmetal-2);
    color: var(--azure);
    font-size: 1rem;
    padding: .8rem;
    border-radius: 25px;
    border: 3px solid var(--dark-slate-blue);
    width: 100%;
    transition: border-color .2s, scale .2s;
}

.inputBox::placeholder{
    color: var(--powder-blue);
}

.inputBox:focus-within{
    outline: none;
    border-color: cyan;
    scale: 1.01;
}

form button {
    background: transparent;
    color: var(--cyan);
    border: 1.5px solid var(--cyan);
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    padding: 1rem 2rem;
    margin: 0 auto;
    cursor: pointer;
    transition: background-color .3s ease, color .5s ease ;
}

form button:hover {
    background-color: var(--cyan-3);
    color: white;
}

.submission-message {
    text-align: center;
}

/* 




------------------------------ Responsive Settings ------------------------------




 */

 @media only screen and (max-width: 750px) {  
    #contact .section-content {
        flex-direction: column;
        gap: 3rem;
    }
    form {
        width: 80%;
        margin: 1rem auto 0 auto;
    }
 }

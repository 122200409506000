:root {
    --navbar-height: 4rem;
    
    --azure: #F0FFFF;
    --cyan: #00FFFF;
    --cyan-2: #64edff;
    --cyan-3: #00e0e0;

    --bright-blue: #0096FF;

    --blue-gray: #7393B3;
    --denim: #6F8FAF;
    --glaucous: #6082B6;
    --pastel-blue: #A7C7E7;
    --powder-blue: #B6D0E2;
    --silver-blue: #AEB6BF;
    --steel-blue: #4682B4;

    --dark-azure: #34495E;
    --dark-slate-blue: #212F3C;
    --dark-slate-blue-2: #121920;
    --dark-gunmetal: #1B2631;
    --dark-gunmetal-2: #17202A;
    
    --cyan-tran: #00ffff20;
    --cyan-tran-2: #00ffff30;
    --bright-blue-tran: #0095ff30;
    --dark-slate-blue-tran: #212f3c80;
    --dark-slate-blue-tran-2: #212f3cD0;
    --dark-gunmetal-tran: rgba(23, 32, 42, 0.3);
    --black-tran: #000000B0;
    --black-tran-2: #000000E0;
  }
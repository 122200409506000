.nav {
  position: fixed;
  display: flex;
  background-color: #00000090;
  backdrop-filter: saturate(150%) blur(5px);
  color: white;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  font-size: small;
  /* transition: transform .3s, background-color .5s; */
  transition: transform .3s, background-color .3s, backdrop-filter .5s;
  z-index: 99;
}

.nav-hidden {
  /* transform: translateY(calc(-1 * var(--navbar-height))); */
  background-color: #00000000;
  backdrop-filter: none;
  box-shadow: none;
}

.logo-wrapper {
  height: 1.2rem;
  margin: auto 5vw;
  cursor: pointer;
}

.logo {
  height: 100%;
}

.desktop-menu {
  position: absolute;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 3.5rem;
  right: 6vw;
  font-size: small;
}

.desktop-menu a {
  padding: 12px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  cursor: pointer;
}

.desktop-menu a:hover {
  background-color: rgb(250, 249, 246);
  color: black;
}

.menu-button {
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  right: 5vw;
}

.menu-button span  {
  color: white;
  font-size: 16px;
}

.menu-icon {
  color: white;
  height: 16px;
  margin-right: 5px;
}

.mobile-menu {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  right: -100vw;
  z-index: 2;
  transition: right .7s ease-in-out;
  pointer-events: none;
}

.show-mobile-menu {
  right: 0vw;
  pointer-events: auto;
}

.mobile-menu-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(13,13,24,.3);
  backdrop-filter: saturate(150%) blur(15px);
  opacity: 0;
  transition: opacity 1s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  letter-spacing: 3px;
  gap: 3rem;
  font-weight: 600;
}

.show-mobile-menu-bg {
  opacity: 1;
}

.mobile-menu-bg a {
  display: block;
  color: white;
  text-decoration: none;
  padding: .5rem;
  transform: translateX(100vw);
  transition: transform 1s;
  cursor: pointer;
}

.mobile-menu-bg a:hover {
  color: var(--cyan);
}

#menuSlide1 {
  transition-delay: 0.1s !important;
  transform: translateX(0) !important;
}

#menuSlide2 {
  transition-delay: 0.3s !important;
  transform: translateX(0) !important;
}

#menuSlide3 {
  transition-delay: 0.5s !important;
  transform: translateX(0) !important;
}

/* 




------------------------------ Responsive Settings ------------------------------




 */

@media only screen and (min-width: 600px) {  
  .desktop-menu {
    display: flex;
  }

  .menu-button {
    display: none;
  }

  .mobile-menu {
    display: none;
  }
}

#projects .section-content {
    max-width: 1000px;
    flex-direction: column;
    gap: 10rem;
}

/* 




------------------------------ Responsive Settings ------------------------------




 */

 @media only screen and (max-width: 750px) {  
    #projects .section-content {
        gap: 3rem;
    }
 }


